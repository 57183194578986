.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flip-card {
  background-color: transparent;
  /* width: 300px; */
  min-height: 380px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb; */
  color: black;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
  padding: 0em;
}

.flip-card-back .desc-link {
  color: #61dafb;
}

.flip-card-back-wrapper {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

.flip-card-back-wrapper::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.flip-card-back-wrapper::-webkit-scrollbar-track {
  background: transparent; 
  padding: 0;
}
 
/* Handle */
.flip-card-back-wrapper::-webkit-scrollbar-thumb {
  border: 1px solid white;
  border-radius: 6px;
}

/* Handle on hover */
.flip-card-back-wrapper::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.1); 
}

